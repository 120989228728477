import env from "@/config/env";
import axios from "axios";

const apiClient = axios.create({
  baseURL: env.apiBaseUrl,
  timeout: 10000,
});

apiClient.interceptors.request.use((config) => {
  // 在这里可以添加认证token
  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // 处理错误响应
    return Promise.reject(error);
  }
);

export default apiClient;
