import { usePage } from "@/context/PageContext";

const Index: React.FC = () => {
  // const navigate = useNavigate();
  const { navPage } = usePage();

  const toggleMusic = () => {
    let audio = document.getElementById("music") as HTMLAudioElement;
    if (audio.paused) {
      audio.play();
      document.querySelector(".play")?.classList.add("playing");
    } else {
      audio.pause();
      document.querySelector(".play")?.classList.remove("playing");
    }
  };

  return (
    <>
      <div className="page">
        <img className="bg" src="/images/img.jpg" alt="background" />
        <img
          className="play playing"
          src="/images/play.png"
          alt=""
          onClick={toggleMusic}
        />
      </div>
    </>
  );
};

export default Index;
