const env = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || "",
  apiKey: process.env.REACT_APP_API_KEY || "",
  debug: process.env.REACT_APP_DEBUG === "true",
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "",
  oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || "",
  oauthClientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET || "",
  environment: process.env.REACT_APP_ENV || "development",
};

export default env;
