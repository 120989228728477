// import createProtectedRoute from "@/utils/createProtectedRoute";
import Index from "@/pages/Index";
import NotFound from "@/pages/NotFound";
import { usePage } from "@/context/PageContext";

// const routes = [
//   {
//     path: "/",
//     element: <Index />,
//   },
//   {
//     path: "/map",
//     element: <Map />,
//   },
//   {
//     path: "/area",
//     element: <Area />,
//   },
//   {
//     path: "/shop",
//     element: <Shop />,
//   },

//   {
//     path: "/rule",
//     element: <Rule />,
//   },
//   {
//     path: "*",
//     element: <NotFound />,
//   },
// ];

// export default routes;

const Routes: React.FC = () => {
  const { page } = usePage();
  let tsx = <></>;
  let p = page.split(",");
  switch (p[0]) {
    case "index":
      tsx = <Index />;
      break;
    default:
      tsx = <NotFound />;
  }
  return tsx;
};

export default Routes;
