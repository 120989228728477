import React, { createContext, useState, useContext, ReactNode } from "react";

interface PageContextType {
  page: string;
  navPage: (e: string) => void;
}

const PageContext = createContext<PageContextType | undefined>(undefined);

export const PageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [page, setPage] = useState("index");

  const navPage = (path: string) => setPage(path);

  return (
    <PageContext.Provider value={{ page, navPage }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePage = (): PageContextType => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error("useAuth must be used within an PageProvider");
  }
  return context;
};
